import _bucket from "./bucket";
import _consumableHash from "./consumable-hash";
var exports = {};
const Bucket = _bucket;
const wrapHash = _consumableHash;
/**
 * @typedef {object} UserBucketOptions
 * @property {(value: Uint8Array) => Promise<Uint8Array>} hashFn
 * @property {number} [bits=8]
 */

/**
 * @param {UserBucketOptions} options
 */

function createHAMT(options) {
  if (!options || !options.hashFn) {
    throw new Error("please define an options.hashFn");
  }

  const bucketOptions = {
    bits: options.bits || 8,
    hash: wrapHash(options.hashFn)
  };
  return new Bucket(bucketOptions);
}

exports = {
  createHAMT,
  Bucket
};
export default exports;
const _createHAMT = exports.createHAMT,
      _Bucket = exports.Bucket;
export { _createHAMT as createHAMT, _Bucket as Bucket };