var exports = {};
const START_MASKS = [255, 254, 252, 248, 240, 224, 192, 128];
const STOP_MASKS = [1, 3, 7, 15, 31, 63, 127, 255];
exports = class ConsumableBuffer {
  /**
   * @param {Uint8Array} value
   */
  constructor(value) {
    this._value = value;
    this._currentBytePos = value.length - 1;
    this._currentBitPos = 7;
  }

  availableBits() {
    return this._currentBitPos + 1 + this._currentBytePos * 8;
  }

  totalBits() {
    return this._value.length * 8;
  }
  /**
   * @param {number} bits
   */


  take(bits) {
    let pendingBits = bits;
    let result = 0;

    while (pendingBits && this._haveBits()) {
      const byte = this._value[this._currentBytePos];
      const availableBits = this._currentBitPos + 1;
      const taking = Math.min(availableBits, pendingBits);
      const value = byteBitsToInt(byte, availableBits - taking, taking);
      result = (result << taking) + value;
      pendingBits -= taking;
      this._currentBitPos -= taking;

      if (this._currentBitPos < 0) {
        this._currentBitPos = 7;
        this._currentBytePos--;
      }
    }

    return result;
  }
  /**
   * @param {number} bits
   */


  untake(bits) {
    this._currentBitPos += bits;

    while (this._currentBitPos > 7) {
      this._currentBitPos -= 8;
      this._currentBytePos += 1;
    }
  }

  _haveBits() {
    return this._currentBytePos >= 0;
  }

};
/**
 * @param {number} byte
 * @param {number} start
 * @param {number} length
 */

function byteBitsToInt(byte, start, length) {
  const mask = maskFor(start, length);
  return (byte & mask) >>> start;
}
/**
 * @param {number} start
 * @param {number} length
 */


function maskFor(start, length) {
  return START_MASKS[start] & STOP_MASKS[Math.min(length + start - 1, 7)];
}

export default exports;